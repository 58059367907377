import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComDateInputComponent } from './com-date-input/com-date-input.component';
import { ComInputComponent } from './com-input/com-input.component';
import { ComTextareaComponent } from './com-textarea/com-textarea.component';
import { ComYearMonthSelectComponent } from './com-year-month-select/com-year-month-select.component';

@NgModule({
    declarations: [ComTextareaComponent, ComInputComponent, ComDateInputComponent, ComYearMonthSelectComponent],
    imports: [CommonModule, NgbModule, ReactiveFormsModule, FormsModule, NgSelectModule],
    exports: [ComTextareaComponent, ComInputComponent, ComDateInputComponent, ComYearMonthSelectComponent],
})
export class SharedModule {}
