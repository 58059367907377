import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import swal, { SweetAlertOptions } from 'sweetalert2';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { ReferralType } from '../enums/referral-type.enum';
import { Referral } from '../model/referral';
import { environment } from './../../../environments/environment';
import { ComBasePopupComponent } from './../component/com-base-popup/com-base-popup.component';
import { ComLoadingComponent } from './../component/com-loading/com-loading.component';
import { BusinessSubject } from './../model/business-subject';
import { ErrorResponse } from './../model/errorResponse.model';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    private overlayRef: OverlayRef | null = null;
    constructor(
        private modalService: NgbModal,
        private overlay: Overlay,
    ) {}

    public getDateMask(): (RegExp | string)[] {
        return [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
    }

    public getAutoCorrectPipe() {
        return (birthDateRestriction: boolean = false) =>
            createAutoCorrectedDatePipe('dd/mm/yyyy', {
                minYear: 1900,
                maxYear: birthDateRestriction ? (moment()?.year() || 2022) - 14 : moment()?.year() || 2022,
            });
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getEducationalLevels(): any[] {
        return [
            { code: '1', title: 'Osnovnošolsko izobraževanje nižje stopnje/nedokončana osnovnošolska izobrazba' },
            { code: '2', title: 'Osnovnošolsko izobraževanje višje stopnje/osnovnošolska izobrazba' },
            { code: '3', title: 'Nižje poklicno in podobno izobraževanje/nižja poklicna in podobna izobrazba' },
            { code: '4', title: 'Srednje poklicno in podobno izobraževanje/srednja poklicna in podobna izobrazba' },
            {
                code: '5',
                title: 'Srednje tehniško in drugo strokovno ter splošno izobraževanje/srednja strokovna in splošna izobrazba',
            },
            {
                code: '6',
                title: ' Visokošolsko izobraževanje prve stopnje in podobno izobraževanje/visokošolska izobrazba prve stopnje in podobna izobrazba',
            },
            {
                code: '6',
                title: 'Višješolsko in višje strokovno izobraževanje/višješolska, višja strokovna in podobna izobrazba',
            },
            {
                code: '6',
                title: 'Visokošolsko izobraževanje prve stopnje, visokošolsko strokovno izobraževanje (prejšnje) in podobno izobraževanje/visokošolska izobrazba prve stopnje, visokošolska strokovna izobrazba (prejšnja) in podobna izobrazba',
            },
            {
                code: '7',
                title: 'Visokošolsko izobraževanje druge stopnje in podobno izobraževanje/visokošolska izobrazba druge stopnje in podobna izobrazba',
            },
            {
                code: '8',
                title: 'Visokošolsko izobraževanje tretje stopnje in podobno izobraževanje/visokošolska izobrazba tretje stopnje in podobna izobrazba',
            },
            {
                code: '8',
                title: 'Magistrsko izobraževanje (prejšnje) in podobno izobraževanje/magisterij znanosti in podobna izobrazba',
            },
            { code: '9', title: 'Doktorsko in podobno izobraževanje/doktorat znanosti in podobna izobrazba' },
        ];
    }

    public isRtcSupported(): boolean {
        const agent = window?.navigator?.userAgent?.toLowerCase();
        return agent.indexOf('trident') < 0;
    }
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openMFPopover(component: any, settings?: { hiddePolicy: boolean }): NgbModalRef {
        const modalNewTerm = this.modalService.open(ComBasePopupComponent, {
            windowClass: 'mf-responsive-popup mf-brand-popup',
            backdrop: 'static',
        });

        modalNewTerm.componentInstance.component = component;
        modalNewTerm.componentInstance.settings = settings;

        return modalNewTerm;
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public showLoading(message: string = 'Prosimo počakajte...') {
        // Returns an OverlayRef (which is a PortalHost)
        // debugger;
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create();
        }
        // Create ComponentPortal that can be attached to a PortalHost
        const spinnerOverlayPortal = new ComponentPortal(ComLoadingComponent);
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
        // component.instance.message = message;
    }

    public hideLoading() {
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.overlayRef) {
            this.overlayRef.detach();
        }
    }

    public fromMomentToNgb(date: moment.Moment): NgbDateStruct | null {
        if (!date) {
            return null;
        }
        return { year: date.year(), month: date.month(), day: date.day() };
    }

    public toMomentFromNgb(date: NgbDateStruct): moment.Moment | null {
        if (!date) {
            return null;
        }
        return moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
    }

    public getVersion(): string {
        return environment.VERSION;
    }

    public parseMFError(error: ErrorResponse): string[] | string {
        if (error) {
            return _.get(error, 'errors', []).map((er: { msg: string }) => {
                return er.msg;
            });
        }
        return '';
    }

    public parseMFHisError(error: ErrorResponse): string | undefined {
        const parsedRawError: string[] | string = this.parseMFError(error);
        if (parsedRawError?.length > 0) {
            const index = parsedRawError[0].indexOf(':') + 1;
            const parsedError1 = parsedRawError[0].substring(index, parsedRawError[0].length);
            let json = [];
            try {
                json = JSON.parse(parsedError1);
                if (json.length > 0) {
                    return json[0].message;
                }
            } catch (error) {
                return undefined;
            }
        }

        return undefined;
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public checkStructError(error: ErrorResponse | any, code: string): boolean {
        error = JSON.parse(error);
        const err = _.get(error, 'errors[0]', undefined);
        if (err) {
            if (err.code === code || err.msg === code) {
                return true;
            }
        }
        return false;
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/ban-types
    public ngxDataTableTransletedMessages: Object = {
        emptyMessage: 'Ni podatkov',
        totalMessage: 'skupaj',
    };

    uuid(length: number = 8): string {
        let i;
        let random;
        let result = '';
        // for (i = 0; i < 32; i++)
        for (i = 0; i < length; i++) {
            random = (Math.random() * 16) | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
        }

        return result;
    }
    getTokenDirectFromStorage() {
        return JSON.parse(localStorage.getItem('@@STATE') || '""')?.basestate?.token?.accessToken;
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getBusiness(bs: { id: number; bsid: number } | any): BusinessSubject {
        //prejmeš Business objekt
        if (bs.bsid !== undefined) {
            return {
                id: bs.bsid,
                name: _.get(bs, 'name', 'Interno'),
                iconPath: _.get(bs, 'iconPath', '../../../assets/svg/network_znak-pink.svg'),
            };
        }
        //prejmeš samo bsId ali drugače definiran Business
        let tmp = bs.id;
        if (tmp === undefined) {
            tmp = bs;
        }
        switch (tmp) {
            case 1:
                return {
                    id: bs.id,
                    name: 'Generali',
                    iconPath: '../../../assets/svg/bs/generali.svg',
                };
            case 2:
                return {
                    id: bs.id,
                    name: 'Triglav',
                    iconPath: '../../../assets/svg/bs/triglav.svg',
                };
            case 3:
                return {
                    id: bs.id,
                    name: 'Merkur',
                    iconPath: '../../../assets/svg/bs/merkur.svg',
                };
            case 4:
                return {
                    id: bs.id,
                    name: 'M zavarovalnica',
                    iconPath: '../../../assets/svg/bs/medifit_icon.svg',
                };
            case 6:
                return {
                    id: bs.id,
                    name: 'Doktor 24',
                    iconPath: '../../../assets/svg/bs/logo_dr24.png',
                };
            case 7:
                return {
                    id: bs.id,
                    name: 'Vzajemna',
                    iconPath: '../../../assets/svg/bs/vzajemna.svg',
                };
            case 8:
                return {
                    id: bs.id,
                    name: 'Medifit',
                    iconPath: '../../../assets/svg/bs/medifit_icon.svg',
                };
            case 9:
                return {
                    id: bs.id,
                    name: 'Sava',
                    iconPath: '../../../assets/svg/bs/sava_icon.svg',
                };
            case -111:
                return {
                    id: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : 'Zunanja rezervacija',
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                };
            default:
                return {
                    id: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : 'Interno',
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                };
        }
    }
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayAlert(title: string, content: string, settings?: SweetAlertOptions): Promise<any> {
        return swal.fire(
            Object.assign(
                {
                    title: title,
                    html: content,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Potrdi',
                    cancelButtonText: 'Prekliči',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-primary m-btn m-btn--custom m-btn--pill mr-2',
                    confirmButtonColor: null,
                    cancelButtonClass: 'btn btn-secondary m-btn m-btn--custom m-btn--pill',
                    cancelButtonColor: null,
                },
                settings,
            ),
        );
    }
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public transformReferralIntoReferralFormData(referral: Referral): Referral | any {
        //temporary (will be moved to BE)
        const { employer, employee } = referral;
        const workplaceTemplate = employee?.workplaceDefinition?.workplaces?.find(w => w.referralType === referral?.formCode)?.workplaceTemplate;
        const initCompanyData = () => {
            return {
                companyName: employer?.name,
                companyAddress: employer?.address,
                companyPostName: employer?.city,
                companyPostCode: employer?.postCode,
                companyTaxNumber: employer?.taxNumber,
                companyIndustryLabel: employer?.economicActivity,
                companyIndustryCode: employer?.skdCode?.slice(1)?.split('.')?.join(''),
            };
        };
        const initCustomerData = () => {
            return {
                customerName: employee?.firstName,
                customerSurname: employee?.lastName,
                customerEmso: employee?.emso,
                customerAddress: employee?.address,
                customerPostName: employee?.city,
                customerPostCode: employee?.postCode,
                customerEducationLabel: employee?.education,
                customerEducationLevel: employee?.educationLevel,
                customerEducationClassification: employee?.profession,
                customerEducationCode1: employee?.educationLevel,
                customerEducationCode2: undefined,
                customerBirthPlace: employee?.birthPlace,
                workingPositionTitle: employee?.jobTitle,
                workingPositionCode: employee?.jobCode,
            };
        };

        const initReferralData = () => {
            return {
                dateOfEvaluation: referral?.riskAssessmentDate || workplaceTemplate?.riskAssessmentDate,
                workingProcessDescription: referral?.riskAssessment || workplaceTemplate?.riskAssessment,
                workplaceToolset: referral?.workEquipment || workplaceTemplate?.workEquipment,
                workplaceObjects: referral?.workplaceAssets || workplaceTemplate?.workplaceAssets,
                workplaceRisks: referral?.riskExposure || workplaceTemplate?.riskExposure,
                workplaceLastImprovements: referral?.measurementsAfterRiskAssessment || workplaceTemplate?.measurementsAfterRiskAssessment,
                personalProtectiveGear: referral?.personalProtectiveGear || workplaceTemplate?.personalProtectiveGear,
                specialHealthRequirements: referral?.specialHealthRequirements || workplaceTemplate?.specialHealthRequirements,
                workplaceInappropriateFor: referral?.workplaceInappropriateFor || workplaceTemplate?.workplaceInappropriateFor,
                employerComments: referral?.employerComments || workplaceTemplate?.employerComments,
                signature: null,
                dateSigned: referral?.date,
                placeOfSignature: referral?.location || employer.settings?.referralDefaultLocation || null,
            };
        };
        if (referral.formCode === ReferralType.F8204.toString()) {
            return {
                ...initCompanyData(),
                ...initCustomerData(),
                ...initReferralData(),
                formCode: referral?.formCode,
                checkType: referral?.checkType,
            };
        } else {
            return {
                ...initCompanyData(),
                ...initCustomerData(),
                ...initReferralData(),
                legalBasisPoint: referral?.legalBasisPoint,
                legalBasisArticle: referral?.legalBasisArticle,
                workingFromDateAtCompany: referral?.employee?.employmentDate,
                workingFromDateAtThisPosition: referral?.employee?.employmentDateForPosition,
                lastMedicalExaminationDate: referral?.employee?.lastExaminationDate,
                formCode: referral?.formCode,
            };
        }
    }
}
