<form *ngIf="filterForm" [formGroup]="filterForm">
    <div class="m-content">
        <div class="mf-content">
            <!-- HEADER-->
            <div class="row mf-o-workers-create">
                <div class="col-10 my-3 d-flex">
                    <div class="pt-2 mf-men-header_book-max">
                        <div (click)="addNewWorker()" class="mf-btn-arrow">
                            <span>
                                <i class="las la-plus"></i>
                                DODAJ NOVEGA
                            </span>
                        </div>
                    </div>
                    <!-- <div class="btn btn-primary" style="margin-left: 60px" (click)="importFromExcel()">
                        <span> <i class="las la-file-excel"></i> UVOZI IZ EXCELA </span>
                    </div> -->
                </div>

                <div class="col-12 my-3">
                    <input formControlName="searchString" type="text" placeholder="Iskanje ..." class="form-control m-input" />
                </div>
            </div>
            <!-- TABLE-->
            <ngx-datatable
                [rows]="allWorkers"
                [columnMode]="'flex'"
                [rowHeight]="55"
                [footerHeight]="50"
                [externalSorting]="true"
                [externalPaging]="true"
                [limit]="sortAndPage.limit"
                [count]="sortAndPage.count"
                [offset]="sortAndPage.currentPage"
                [messages]="{ emptyMessage: 'Ni podatkov za prikaz.', totalMessage: 'skupaj' }"
                [selectionType]="SelectionType.single"
                (page)="onPage($event)"
                (select)="workerSelected($event)"
                (sort)="onSort($event)"
                class="bootstrap mf-o-workers-table"
            >
                <!-- @TODO: Ignored manually on 2023-10-13 -->
                <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
                <ng-container *ngFor="let column of columnsArray">
                    <!-- eslint-enable -->
                    <ngx-datatable-column [flexGrow]="1" name="{{ column.name }}" prop="{{ column.prop }}">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            <span *ngIf="value?.length > 20; else normalText" container="body" ngbTooltip="{{ value }}">
                                {{ value | slice: 0 : 17 }} ...
                            </span>
                            <ng-template #normalText>
                                {{ value || '/' }}
                            </ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
                <ngx-datatable-column [canAutoResize]="false" [sortable]="false" [flexGrow]="1" [cellClass]="'mf-action-column'" name="AKCIJE">
                    <ng-template let-row="row" let-value="value " let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <a
                            (click)="bookWorker(row); $event.stopPropagation()"
                            href="#"
                            placement="left"
                            ngbTooltip="Naroči na pregled"
                            class="btn p-1 m-btn m-btn--hover-brand"
                        >
                            <i style="font-size: 1.2em" class="las la-plus-square"></i>
                        </a>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</form>
