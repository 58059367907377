<div *ngIf="workerForm" class="mf-content">
    <!-- WORKER FORM-->
    <div class="mt-4">
        <form [formGroup]="workerForm">
            <div class="form__title mb-1">OBVEZNI PODATKI DELAVCA</div>
            <div class="p-2 form--red">
                <!-- row1 -->
                <div class="row">
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">PRIIMEK</label>
                            <input
                                type="text"
                                formControlName="lastName"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.lastName.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">IME</label>
                            <input
                                type="text"
                                formControlName="firstName"
                                class="form-control m-input"
                                [ngClass]="{ 'is-invalid': workerFormData?.firstName.errors && isSubmitted }"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">DATUM ROJSTVA</label>
                            <input
                                [textMask]="{
                                    mask: dateMask,
                                    keepCharPositions: true,
                                    pipe: autoCorrectDatePipe(true)
                                }"
                                type="text"
                                placeholder="DD.MM.YYYY"
                                formControlName="birthDate"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.birthDate.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                </div>
                <!-- row2 -->
                <div class="row">
                    <div class="col-12 col-xl-5">
                        <div class="form-group m-form__group">
                            <label class="form-control-label d-flex flex-wrap align-items-center">
                                NASLOV
                                <div *ngIf="bsAdditionalInfo?.taxNumber" class="form-check-inp mf-big ml-2 mb-0">
                                    <a class="cursor-pointer">
                                        <span (click)="useCompanyAddress()" class="m-badge m-badge--primary p-1 px-2">UPORABI NASLOV PODJETJA</span>
                                    </a>
                                </div>
                            </label>
                            <input
                                type="text"
                                formControlName="address"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.address.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 pt-1">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">POŠTNA ŠT.</label>
                            <ng-select
                                [items]="zipCodes"
                                [clearable]="false"
                                [addTag]="true"
                                (change)="fillCity($event)"
                                bindLabel="zipCode"
                                bindValue="zipCode"
                                addTagText="Dodaj poštno št."
                                placeholder="Iskanje ..."
                                formControlName="postCode"
                                class="w-100"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.postCode?.errors && isSubmitted
                                }"
                            >
                                <ng-template ng-option-tmp let-item="item">
                                    <div>
                                        <span>{{ item.zipCode }}</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-5 pt-1">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">POŠTA</label>
                            <ng-select
                                [items]="zipCodes"
                                [clearable]="false"
                                [addTag]="true"
                                (change)="fillPost($event)"
                                bindLabel="city"
                                name="city"
                                bindValue="city"
                                addTagText="Dodaj pošto"
                                placeholder="Iskanje ..."
                                formControlName="city"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.city?.errors && isSubmitted
                                }"
                            >
                                <ng-template style="overflow-y: scroll" ng-option-tmp let-item="item">
                                    <div>
                                        <span>{{ item.city }}</span>
                                        <span class="font-sm text-secondary">&nbsp;({{ item.zipCode }})</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <!-- row3-->
                <div class="row">
                    <div class="col-12 col-xl-7">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">DELOVNO MESTO</label>
                            <input
                                type="text"
                                formControlName="jobTitle"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData.jobTitle?.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xl-5">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">SPOL</label>
                            <div
                                class="d-flex pt-2 form-control"
                                [ngClass]="{
                                    'is-invalid': workerFormData.gender?.errors && isSubmitted
                                }"
                            >
                                <div class="form-check-inp mf-big mr-2 m-radio">
                                    <input
                                        id="female"
                                        type="radio"
                                        formControlName="gender"
                                        value="FEMALE"
                                        class="mr-1 cursor-pointer"
                                        i18n="@@cf8418a1-1a75-540a-b8d7-311dba5e1d9d"
                                    />
                                    <label for="female" class="form-check-label mf-input-text">Ženski</label>
                                </div>
                                <div class="form-check-inp mf-big m-radio">
                                    <input
                                        id="male"
                                        type="radio"
                                        formControlName="gender"
                                        value="MALE"
                                        class="mr-1 cursor-pointer"
                                        i18n="@@d0210f3d-ee1b-5a95-9721-61d189edfc16"
                                    />
                                    <label for="male" class="form-check-label mf-input-text">Moški</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form__title mb-1 mt-3">NEOBVEZNI PODATKI DELAVCA</div>
            <div class="p-2 form--red">
                <!-- row4-->
                <div class="row">
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">EMŠO</label>
                            <input
                                type="text"
                                formControlName="emso"
                                class="form-control m-input"
                                [ngClass]="{ 'is-invalid': workerFormData?.emso.errors && isSubmitted }"
                            />
                            <div *ngIf="workerFormData?.emso.errors && isSubmitted">
                                <small [hidden]="!workerFormData?.emso.errors">Napačna oblika.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">TELEFON</label>
                            <input
                                type="text"
                                formControlName="phoneNumber"
                                class="form-control m-input"
                                [ngClass]="{ 'is-invalid': workerFormData?.phoneNumber.errors && isSubmitted }"
                            />
                            <div *ngIf="workerFormData?.phoneNumber.errors && isSubmitted">
                                <small [hidden]="!workerFormData?.phoneNumber.errors.pattern">Dovoljene so samo številke.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">EMAIL</label>
                            <input
                                type="text"
                                formControlName="email"
                                class="form-control m-input"
                                [ngClass]="{ 'is-invalid': workerFormData?.email.errors && isSubmitted }"
                            />
                            <div *ngIf="workerFormData?.email.errors && isSubmitted">
                                <small [hidden]="!workerFormData?.email.errors">Napačna oblika.</small>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- row5-->
                <div class="row">
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">PERIODIKA PREGLEDOV</label>
                            <i ngbTooltip="Periodika v mesecih" class="las la-info-circle ml-1"></i>
                            <input
                                formControlName="periodicInspectionMonths"
                                type="number"
                                placeholder="Periodika v mesecih"
                                min="0"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.periodicInspectionMonths?.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">KRAJ ROJSTVA</label>
                            <input type="text" formControlName="birthPlace" class="form-control m-input" />
                        </div>
                    </div>
                </div>
                <!-- row6-->
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">IZOBRAZBA</label>
                            <input
                                #instance="ngbTypeahead"
                                [ngbTypeahead]="searchDegree"
                                [resultTemplate]="degreeResult"
                                [resultFormatter]="degreeFormatter"
                                [editable]="false"
                                type="text"
                                placeholder="Iskanje ..."
                                formControlName="education"
                                container="body"
                                class="form-control"
                            />
                            <ng-template #degreeResult let-r="result" let-t="term">
                                <b>({{ r.code }})</b>
                                {{ r.title }}
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">POKLIC</label>
                            <i
                                container="body"
                                ngbTooltip="V primeru izbire poklica iz šifranta, se koda poklica samodejno izpolni"
                                class="las la-info-circle ml-1"
                            ></i>
                            <div class="form-group m-form__group mfa-custom-type-ahead">
                                <input
                                    #instance="ngbTypeahead"
                                    [ngbTypeahead]="searchSkp"
                                    [resultTemplate]="skp"
                                    [resultFormatter]="skpFormatter"
                                    [editable]="true"
                                    type="text"
                                    formControlName="profession"
                                    placeholder="Iskanje ..."
                                    container="body"
                                    class="form-control"
                                    [ngClass]="{
                                        'is-invalid': workerFormData?.profession?.errors && isSubmitted
                                    }"
                                />
                                <ng-template #skp let-r="result" let-t="term">
                                    ({{ r.code }})
                                    <span *ngIf="workerFormData?.gender?.value === genderTypes.FEMALE; else male">
                                        {{ r.descriptionFemale }}
                                    </span>
                                    <ng-template #male>
                                        {{ r.descriptionMale }}
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 pl-0 pr-3">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">KODA POKLICA</label>
                            <input
                                type="text"
                                formControlName="skpCode"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.skpCode?.errors && isSubmitted
                                }"
                            />
                            <div *ngIf="workerFormData?.skpCode?.errors && isSubmitted">
                                <small [hidden]="!workerFormData?.skpCode?.errors?.maxlength">Dovoljenih 6 znakov.</small>
                                <small [hidden]="!workerFormData?.skpCode?.errors?.required">Obvezen podatek</small>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- row7-->
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">KODA DELOVNEGA MESTA</label>
                            <i ngbTooltip="4 mestna koda delovnega mesta" class="las la-info-circle ml-1"></i>
                            <input
                                type="text"
                                formControlName="jobCode"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.jobCode?.errors && isSubmitted
                                }"
                            />
                            <div *ngIf="workerFormData?.jobCode?.errors && isSubmitted">
                                <small [hidden]="workerFormData?.jobCode?.errors?.pattern">Dovoljeni so 4 znaki.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">KADROVSKA ŠTEVILKA</label>
                            <i ngbTooltip="Interna" class="las la-info-circle ml-1"></i>
                            <input type="text" formControlName="personnelNumber" class="form-control m-input" />
                        </div>
                    </div>
                </div>
                <!--row8-->
                <div *ngIf="isWorkGroupsModuleActive" class="row">
                    <div class="col-12 col-xl-6">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">DELOVNA SKUPINA</label>
                            <div class="form-group m-form__group mfa-custom-type-ahead">
                                <input
                                    #instance="ngbTypeahead"
                                    [ngbTypeahead]="searchWorkGroups"
                                    [resultTemplate]="groups"
                                    [inputFormatter]="workGroupFormatter"
                                    [editable]="false"
                                    type="text"
                                    placeholder="Iskanje ..."
                                    formControlName="workGroup"
                                    container="body"
                                    class="form-control"
                                />
                                <ng-template #groups let-r="result" let-t="term">
                                    {{ r.name }}
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- row9-->
                <div class="row">
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">
                                DATUM ZADNJEGA
                                <span ngbTooltip="Preventivnega zdravstvenega" conatiner="body">PR.</span>
                                PREGLEDA
                            </label>
                            <input
                                [textMask]="{ mask: dateMask, keepCharPositions: true, pipe: autoCorrectDatePipe() }"
                                type="text"
                                placeholder="DD.MM.YYYY"
                                formControlName="lastExaminationDate"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.lastExaminationDate.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">DATUM ZAPOSLITVE</label>
                            <input
                                [textMask]="{ mask: dateMask, keepCharPositions: true, pipe: autoCorrectDatePipe() }"
                                type="text"
                                placeholder="DD.MM.YYYY"
                                formControlName="employmentDate"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.employmentDate.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <div class="form-group m-form__group">
                            <label class="form-control-label">
                                DATUM ZAPOSLITVE NA
                                <span ngbTooltip="Delovnem mestu">D.M.</span>
                            </label>
                            <input
                                [textMask]="{ mask: dateMask, keepCharPositions: true, pipe: autoCorrectDatePipe() }"
                                type="text"
                                placeholder="DD.MM.YYYY"
                                formControlName="employmentDateForPosition"
                                class="form-control m-input"
                                [ngClass]="{
                                    'is-invalid': workerFormData?.employmentDateForPosition.errors && isSubmitted
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- FOOTER-->
    <div class="mt-2 py-2 pl-1 form__footer">
        <button *ngIf="employeeData && !workerForm.pristine" (click)="submitWorkerData()" class="btn btn-primary">
            <span>SHRANI</span>
        </button>
        <button *ngIf="!employeeData && !workerForm?.pristine" (click)="submitWorkerData()" class="btn btn-primary p-2">
            <span>DODAJANJE</span>
        </button>
    </div>
</div>
