import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NavigateService } from 'src/app/_common/services/navigate.service';
import { MenuStateActions } from 'src/app/_common/state/menu/menu.actions';
import { ReferralType } from '../../_common/enums/referral-type.enum';
import { WorkplaceDefinition } from '../../_common/model/work-groups-template';
import { LoaderService } from '../../_common/services/loader.service';
import { BaseState } from '../../_common/state/base/base.state';
import { WorkGroupDetailsComponent } from './components/work-group-details/work-group-details.component';
import { WorkingGroupsService } from './working-groups.service';

@Component({
    selector: 'app-work-groups',
    templateUrl: './working-groups.component.html',
    styleUrls: ['./working-groups.component.scss'],
})
export class WorkGroupsComponent implements OnInit, OnDestroy {
    constructor(
        private store: Store,
        private navigate: NavigateService,
        private workingGroupsService: WorkingGroupsService,
        private loader: LoaderService,
        private fb: UntypedFormBuilder,
    ) {}

    public employerId = this.store.selectSnapshot(BaseState.getEmployerId);
    private onDestroy$ = new Subject<void>();
    public workingGroups: WorkplaceDefinition[];
    public searchForm: UntypedFormGroup;
    public ReferralType: typeof ReferralType = ReferralType;

    public sortAndPage = {
        order: 'created_at',
        orderDirection: 'desc',
        count: 0,
        offset: 0,
        limit: 20,
        currentPage: 0,
    };

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (window.innerWidth > 0) {
            this.sortAndPage.limit = Math.floor((window.innerHeight - 350) / 50);
            this.getWorkplaces();
        }
    }

    ngOnInit(): void {
        this.onResize();
        this.initForm();
        this.store.dispatch(
            new MenuStateActions.SetHeaderData({
                iconClass: 'las la-users',
                title: 'Delovne skupine',
                description: 'skupine delavcev za avtomatsko izpolnjevanje napotnic',
            }),
        );
        this.navigate.onCloseSidePanel.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.getWorkplaces(true);
        });
    }

    public hasTemplate(templateType: ReferralType, workplaceDefinition?: WorkplaceDefinition) {
        return workplaceDefinition?.workplaces?.find(workplace => workplace.referralType === templateType);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.navigate.closeSidePanel();
    }

    public initForm() {
        this.searchForm = this.fb.group({
            name: this.fb.control({ value: undefined, disabled: false }),
        });

        this.searchForm.controls.name.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
            this.sortAndPage.currentPage = 0;
            this.sortAndPage.offset = 0;
            this.getWorkplaces();
        });
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSort(event: any) {
        const { sorts } = event;
        const { prop, dir: orderDirection } = sorts[0];

        if (prop && orderDirection) {
            this.sortAndPage.order = prop;
            this.sortAndPage.orderDirection = orderDirection;
            // Set page and offset to 0 since ngx-datatable switches back to page 1 when sorting
            this.sortAndPage.currentPage = 0;
            this.sortAndPage.offset = 0;
            this.getWorkplaces();
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onPage(event: any) {
        const { pageSize: limit, offset } = event;
        this.sortAndPage.limit = limit;
        this.sortAndPage.offset = offset * limit;
        this.getWorkplaces();
    }

    public getWorkplaces(forceUpdate: boolean = false) {
        this.loader.setLoading(true);

        this.workingGroupsService
            .getWorkplaceDefinitions(this.employerId, this.sortAndPage, this.searchForm?.controls?.name?.value, forceUpdate)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                ({ data: workingGroups, metadata }) => {
                    this.sortAndPage.count = metadata.totalCount;
                    this.sortAndPage.currentPage = metadata.currentPage - 1;
                    this.workingGroups = workingGroups;
                    this.loader.setLoading(false);
                },
                // @TODO: Ignored with eslint-interactive on 2023-09-15
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                err => this.loader.setLoading(false),
            );
    }

    public addNewWorkGroup() {
        this.navigate.openSidePanel({
            component: WorkGroupDetailsComponent,
            params: {
                setData: undefined,
            },
            title: '',
        });
    }

    public editWorkGroup({ selected }: { selected: WorkplaceDefinition[] }) {
        if (!selected || !selected.length) {
            return;
        }
        if (!selected[0].id) {
            return;
        }
        this.workingGroupsService.getWorkplaceDefinition(selected[0]?.id).subscribe(workplaceDefinition => {
            this.navigate.openSidePanel({
                buttonsData: {
                    spaceBetween: false,
                    buttons: [
                        {
                            text: 'IZBRIS SKUPINE',
                            buttonClass: 'btn btn-primary',
                            iconClass: 'la-trash',
                            onClick: (instance: WorkGroupDetailsComponent) => {
                                instance.deleteWorkplaceDefinition();
                            },
                        },
                    ],
                },
                component: WorkGroupDetailsComponent,
                params: {
                    setData: workplaceDefinition,
                },
                title: '',
            });
        });
    }
}
