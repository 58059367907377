import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SidePanelClose } from './../model/side-panel-close';
import { SidePanelData } from './../model/side-panel-data';

@Injectable({
    providedIn: 'root',
})
export class NavigateService {
    private messageSource = new BehaviorSubject({
        open: false,
        title: '',
    });
    public currentStatus = this.messageSource.asObservable();
    public onCloseSidePanel = new Subject<SidePanelClose | undefined>();

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // changeMessage(message: string) {
    //   this.messageSource.next(message)
    // }

    public getStateSnapshot() {
        return this.messageSource.getValue();
    }

    public openSidePanel(sudePanelData: SidePanelData): void {
        // this.currentValue;
        this.messageSource.next({
            open: true,
            ...sudePanelData,
        });
    }

    public closeSidePanel(sidePanelClose?: SidePanelClose): void {
        this.messageSource.next({
            open: false,
            title: '',
        });
        this.onCloseSidePanel.next(sidePanelClose);
    }
}
